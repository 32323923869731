<template>
  <div>
    <el-container>
      <!-- el-aside -->
      <el-aside width="220px">
        <el-select
          @change="onChangeItemState"
          v-model="selectItemState"
          placeholder="请选择项目状态"
        >
          <el-option
            v-for="item in itemState"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-tree
          :data="treeData"
          node-key="id"
          draggable
          accordion
          :highlight-current="true"
          :default-expanded-keys="defaultExpandedKeys"
          :allow-drag="allowDrag"
          :allow-drop="allowDrop"
          :current-node-key="currentNodeKey"
          @node-drag-start="nodeDragStart"
          @node-drag-enter="nodeDragEnter"
          @node-drag-leave="nodeDragLeave"
          @node-drag-over="nodeDragOver"
          @node-drag-end="nodeDragEnd"
          @node-drop="nodeDrop"
          @node-click="nodeClick"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span>
              <el-dropdown>
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="onAddTree(node, data)"
                    >新建子类</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="onEditTree(node, data)"
                    divided
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="onDeleteTree(node, data)"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </span>
        </el-tree>
      </el-aside>
      <!-- end el-aside -->
      <!-- el-main -->
      <el-main>
        <!-- top -->
        <div class="top">
          <el-radio-group
            @change="onChange"
            v-model="selectItemType"
            size="mini"
          >
            <el-radio-button label="消费项目"></el-radio-button>
            <el-radio-button label="预约项目"></el-radio-button>
            <el-radio-button label="产品管理"></el-radio-button>
          </el-radio-group>
          <div class="right">
            <el-input placeholder="请输入内容" v-model="searchText" size="mini">
              <template slot="append">
                <i @click="onSearchItem" class="el-icon-search"></i>
              </template>
            </el-input>
            <el-button
              @click="addItem"
              size="mini"
              type="primary"
              icon="el-icon-plus"
              >新增项目</el-button
            >
          </div>
        </div>
        <!-- end top -->
        <!-- table -->
        <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          height="calc(100vh - 260px)"
        >
          <el-table-column type="selection" width="48"></el-table-column>
          <el-table-column prop="date" label="项目" width="250">
            <template slot-scope="scope">
              <div class="image-text">
                <el-image
                  :src="
                    scope.row.Picture
                      ? scope.row.Picture
                      : require('../../../../assets/img/empty.jpg')
                  "
                ></el-image>
                <div class="text">
                  <div>{{ scope.row.ItemName }}</div>
                  <div class="size" @click="onClipboard(scope.row.ItemGuid)">
                    编号: {{ scope.row.ItemNo }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="ClassName"
            label="分类"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="Price"
            label="单价"
            width="100"
          ></el-table-column>
          <el-table-column prop="ItemState" label="项目状态" width="100">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.ItemState == 1"
                >正常</el-tag
              >
              <el-tag type="info" v-if="scope.row.ItemState == 2"
                >已停用</el-tag
              >
              <el-tag type="danger" v-if="scope.row.ItemState == 3"
                >已删除</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column prop="ListDeptName" label="所属部门">
          </el-table-column>
          <el-table-column prop="IsSale" label="扣库存">
            <template slot-scope="scope">{{
              scope.row.IsSale | whether
            }}</template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                @click="onAlterItem(scope.row)"
                :disabled="scope.row.ItemState !== 1"
                type="primary"
                size="mini"
                >修改</el-button
              >
              <el-button
                @click="onDeleteItem(scope.row)"
                :disabled="scope.row.ItemState !== 1"
                type="danger"
                size="mini"
                >删除</el-button
              >
            </template>
          </el-table-column> </el-table
        ><!-- end table -->

        <!-- pagination -->
        <el-pagination
          background
          :page-sizes="[15, 20, 30, 40, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalRecord"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
        <!--pagination-->

        <EditDialog
          ref="blow"
          :etitle="etitle"
          :dialogTableVisible="showDialog"
          :callFatherFunction="callFatherFunction"
          :fromData="fromData"
          :roundItem="roundItem"
          :deptList="deptList"
          :dutyList="dutyList"
          :itemBasicList="itemBasicList"
          :itemClassList="itemClassList"
          :itemWechatList="itemWechatList"
          :levelList="levelList"
          :unitsList="unitsList"
          @closeDialog="closeDialog"
        >
        </EditDialog>
      </el-main>
      <!-- end el-main -->
    </el-container>
    <!-- el-dialog -->
    <el-dialog
      :title="dialogData.title"
      :visible.sync="dialogData.isDialog"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form>
        <el-form-item :label="dialogData.label">
          <el-input v-model="treeShowName" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item class="left">
          <el-button @click="onCancelTree">取消</el-button>
          <el-button @click="onConfirmTree" type="primary">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- end el-dialog -->
  </div>
</template>

<script>
import service from "@/api/service.js";
import EditDialog from "./components/edit-dialog.vue";
export default {
  components: { EditDialog },

  directives: {},

  data() {
    return {
      loading: true,
      treeData: [],
      currentNodeKey: "ALL",
      defaultExpandedKeys: ["ALL"],
      treeGuid: "",
      treeShowName: "",
      treeType: "",
      dialogData: {
        // 对话框数据
        title: "",
        isDialog: false,
        label: "",
      },
      itemState: [
        { value: 1, label: "项目 - 正常" },
        { value: 2, label: "项目 - 已停用" },
        { value: 3, label: "项目 - 已删除" },
      ],
      selectItemState: 1,
      searchText: "",
      selectItemType: "消费项目",
      selectItemTypeValue: 1,
      tableData: [],
      pageIndex: 1,
      pageSize: 15,
      totalRecord: 0,
      fromData: {},
      roundItem: [],
      deptList: [],
      dutyList: [],
      itemBasicList: [],
      itemClassList: [],
      unitsList: [],
      itemWechatList: [],
      levelList: [],
      showDialog: false,
      etitle: "",
    };
  },

  mounted() {
    this.initPageData();
    this.initTreeData();
  },

  filters: {
    whether(param) {
      return param == 0 ? "否" : "是";
    },
  },

  methods: {
    async initTreeData() {
      try {
        let { data } = await service.getItemshowTree();
        this.treeData = data;
      } catch (e) {}
    },

    async initPageData() {
      try {
        let submitData = {
          page_index: this.pageIndex,
          page_size: this.pageSize,
          word: this.searchText,
          item_state: this.selectItemState,
          item_type: this.selectItemTypeValue,
          show_guid: this.currentNodeKey,
        };
        let { data, errcode } = await service.getItemMaintItemList(submitData);
        // console.log(data);
        if (errcode == 0) {
          let { filter, itemMaintItemList } = data;
          this.tableData = itemMaintItemList;
          this.totalRecord = filter.TotalRecord;
        } else {
          // console.log(data);
        }
      } catch (e) {
        // console.log(e);
      }
      this.loading = false;
    },

    onClipboard(text) {
      let that = this;
      // console.log(text);
      this.$copyText(text).then(() => {
        that.$message.success("已复制到剪贴板!");
      });
    },

    // 选择项目状态
    onChangeItemState() {
      this.initPageData();
    },

    // 节点开始拖拽时触发的事件
    nodeDragStart(dragNode, event) {
      // console.log(dragNode, event);
    },

    // 拖拽进入其他节点时触发的事件
    nodeDragEnter(dragNode, enterNode, event) {
      // console.log(dragNode, enterNode, event);
    },

    // 拖拽离开某个节点时触发的事件
    nodeDragLeave(dragNode, enterNode, event) {
      // console.log(dragNode, enterNode, event);
    },

    // 在拖拽节点时触发的事件
    nodeDragOver(dragNode, enterNode, event) {
      // console.log(dragNode, enterNode, event);
    },

    // 拖拽结束时（可能未成功）触发的事件
    nodeDragEnd(dragNode, enterNode, type, event) {
      // console.log(dragNode, enterNode, type, event);
    },

    // 拖拽成功完成时触发的事件, type: ( before: 之前、 after: 之后、 inner：进入 )
    nodeDrop(dragNode, enterNode, type, event) {
      // console.log(dragNode, enterNode, type, event);
    },

    //
    nodeClick(data, dragNode, enterNode) {
      this.currentNodeKey = data.id;
      this.defaultExpandedKeys = [data.id];
      this.initPageData();
    },

    // 判断节点能否被拖拽, 如果为 false 不允拖动。
    allowDrag(draggingNode) {
      return draggingNode.data.label.indexOf("全部显示组") === -1;
    },

    allowDrop(draggingNode, dropNode, type) {
      // console.log(draggingNode, dropNode, type);
    },

    // 添加节点
    onAddTree(event, data) {
      this.treeType = "add";
      this.treeGuid = data.id;
      this.treeShowName = "";
      this.dialogData.title = "新建子类";
      this.dialogData.label = "分类名称";
      this.dialogData.isDialog = true;
    },

    // 修改节点
    onEditTree(event, data) {
      this.treeType = "edit";
      this.treeShowName = data.label;
      this.treeGuid = data.id;
      this.dialogData.title = "修改分类";
      this.dialogData.label = "分类名称";
      this.dialogData.isDialog = true;
    },

    // 删除节点
    onDeleteTree(event, _data) {
      this.$confirm("是否删除该分类?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode } = await service.deleteItemShow({
              show_guid: _data.id,
            });
            if (errcode == 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.dialogData.isDialog = false;
              this.initTreeData();
            } else {
              this.$message.error("删除失败!");
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch(() => {});
    },

    onCancelTree() {
      this.dialogData.isDialog = false;
    },

    async onConfirmTree() {
      try {
        if (this.treeType == "add") {
          let submitData = {
            FatherGuid: this.treeGuid,
            ShowName: this.treeShowName,
          };
          let { data, errcode } = await service.addItemShow(submitData);
          if (errcode == 0) {
            this.dialogData.isDialog = false;
            this.initTreeData();
            this.$message({
              type: "success",
              message: "添加成功!",
            });
          } else {
            this.$message.error("添加失败!");
          }
        } else {
          let submitData = {
            ShowGuid: this.treeGuid,
            ShowName: this.treeShowName,
          };
          let { data, errcode } = await service.updateItemShow(submitData);
          if (errcode == 0) {
            this.dialogData.isDialog = false;
            this.initTreeData();
            this.$message({
              type: "success",
              message: "修改成功!",
            });
          } else {
            this.$message.error("修改失败!");
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    // 改变页面数据条数
    handleSizeChange(event) {
      this.pageIndex = 1;
      this.pageSize = event;
      this.initPageData();
    },

    // 改变页面码
    handleCurrentChange(event) {
      this.pageIndex = event;
      this.initPageData();
    },

    onChange(event) {
      switch (event) {
        case "消费项目":
          this.selectItemTypeValue = 1;
          break;
        case "预约项目":
          this.selectItemTypeValue = 2;
          break;
        case "产品管理":
          this.selectItemTypeValue = 3;
          break;
        default:
          break;
      }
      this.pageIndex = 1;
      this.initPageData();
    },

    dataFormat() {
      return {
        ShowGuid: this.currentNodeKey,
        ItemName: "",
        ClassGuid: "",
        Mnemonic: "",
        Barcode: "",
        LevelGuid: "",
        ListDeptGuid: [],
        ItemNote: "",
        WechatGuid: "",
        PriceChange: 0,
        CountChange: 0,
        ModifyRoya: 0,
        BookingTime: "",
        RoundItem: 0,
        Price: "",
        Picture: "",
        PictureUrl: "",
        ItemSell: 0,
        ListBasicGuid: [],
        IgnoreDuty: [],
      };
    },

    async initItemData(item_guid) {
      try {
        let submitData = item_guid ? { item_guid } : {};
        let { data } = await service.initSaveItemData(submitData);
        let {
          RoundItem,
          deptList,
          dutyList,
          itemBasicList,
          itemClassList,
          itemWechatList,
          levelList,
          initItem,
          unitsList,
        } = data;
        let etitle = await "修改项目";
        if (!initItem) {
          initItem = await this.dataFormat();
          etitle = await "添加项目";
        }

        if(initItem.LevelGuid && initItem.LevelGuid.length > 4){
          for(let i=0; i< levelList.length; i++){
            if(levelList[i].LevelGuid == initItem.LevelGuid){
              for(let j=0;j<dutyList.length;j++){
                if(dutyList[j].DutyNo == levelList[i].DutyNo){
                  initItem.DutyGuid = dutyList[j].DutyNo
                  break;
                }
              }
            }
          }
        }else{
          let arr = dutyList.filter(item=>item.DutyNo == initItem.LevelGuid)
          arr.length ? initItem.DutyGuid = arr[0].DutyNo : ''
          initItem.LevelGuid = initItem.LevelGuid ? "ALL" : ''
        }

        this.roundItem = RoundItem;
        this.deptList = deptList;
        this.dutyList = dutyList;
        this.itemBasicList = itemBasicList;
        this.itemClassList = itemClassList;
        this.unitsList = unitsList;
        this.itemWechatList = itemWechatList;
        this.levelList = levelList;
        this.fromData = initItem;
        this.etitle = etitle;
        this.showDialog = true;
      } catch (e) {}
    },

    // 添加项目
    addItem() {
      if (this.currentNodeKey == "ALL") {
        this.$message({
          message: "请选择左边的显示组, 在点击‘新增项目’",
          type: "warning",
        });
        return;
      }
      this.initItemData();
    },

    async onAlterItem(event) {
      await this.initItemData(event.ItemGuid);
      await this.$refs.blow.onChangeDuty(this.fromData.DutyGuid)
      this.$refs.blow.dialog = true;
    },

    onDeleteItem(event) {
      this.$confirm(`是否删除该‘${event.ItemName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await service.deleteItem({
              itemGuidList: [event.ItemGuid],
            });
            if (errcode == 0) {
              this.$message({
                message: "删除成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch((err) => {});
    },

    // 搜索项目
    onSearchItem() {
      this.pageIndex = 1;
      this.initPageData();
    },

    //
    callFatherFunction() {
      this.showDialog = false;
    },

    closeDialog() {
      this.loading = true;
      this.initPageData();
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  margin-top: 20px;
  .el-aside {
    height: calc(100vh - 160px);
    border-right: 1px solid #d3dce6;
    .el-select {
      width: 219px;
      margin-bottom: 10px;
      box-sizing: border-box;
    }
    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 0;
      .el-dropdown-link {
        display: inline-block;
        height: 36px;
        width: 36px;
        text-align: center;
        line-height: 36px;
        color: #bfcbd9;
      }
      .el-dropdown-link:hover {
        background-color: #d3dce6;
        color: #8492a6;
      }
    }
  }
  .el-main {
    position: relative;
    padding: 0 0 0 20px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .right {
        display: flex;
        align-items: center;
      }
      .el-input {
        width: 300px !important;
      }
      .el-button {
        margin-left: 10px;
      }
    }

    .el-table {
      .image-text {
        display: flex;
        align-items: center;
        .image-slot {
          i {
            font-size: 50px;
            color: #8492a6;
          }
        }
        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 56px;
          margin-left: 10px;
          width: 200px;
          div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .size {
            font-size: 10px;
          }
        }
      }
      .el-image {
        width: 56px;
        height: 56px;
        border-radius: 5px;
        vertical-align: middle;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .el-pagination {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.el-dialog {
  .left {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }
}
</style>
