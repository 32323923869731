<template>
  <div>
    <!--  -->
    <el-dialog
      :title="etitle"
      top="3%"
      width="50%"
      :visible.sync="dialogTableVisible"
      :show-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog('formRules')"
    >
      <el-form
        :model="fromData"
        :rules="formRules"
        ref="formRules"
        label-width="100px"
        label-position="left"
      >
        <div class="auto">
          <div class="dialog-top">
            <div class="left">
              <DFUpload
                ref="DFUpload"
                @success="onUploadSuccess"
                @error="onUploadError"
                @remove="onRemove"
              >
              </DFUpload>
            </div>
            <div class="right">
              <el-form-item label="项目名称" prop="ItemName">
                <el-input v-model="fromData.ItemName"></el-input>
              </el-form-item>
              <el-form-item label="折扣分类" prop="ClassGuid">
                <el-select
                  v-model="fromData.ClassGuid"
                  placeholder="请选择分类设提成"
                >
                  <el-option
                    v-for="(item, index) in itemClassList"
                    :label="item.ClassName"
                    :value="item.ClassGuid"
                    :key="item.ClassGuid"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="项目单位" prop="UnitsGuid">
                <el-select
                  v-model="fromData.UnitsGuid"
                  placeholder="请选择项目单位"
                >
                  <el-option
                    v-for="(item, index) in unitsList"
                    :label="item.UnitsName"
                    :value="item.UnitsGuid"
                    :key="item.UnitsGuid"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="dialog-center">
            <div class="tabs">
              <div
                v-for="(item, index) in tabsData"
                :key="index"
                :class="isAction == index ? 'action' : ''"
                @click="onTabs(index)"
              >
                {{ item }}
              </div>
            </div>
            <div class="tabs-chile" v-show="isAction == 0">
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="单价" label-width="80px" prop="date1">
                      <el-input v-model="fromData.Price">
                        <div slot="append">元</div>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="条码" label-width="80px" prop="date1">
                      <el-input v-model="fromData.Barcode"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="助记符"
                      label-width="80px"
                      prop="date2"
                    >
                      <el-input v-model="fromData.Mnemonic"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item
                      label="触发过牌"
                      label-width="80px"
                      prop="date1"
                    >
                      <el-select
                        v-model="fromData.RoundItem"
                        placeholder="请选择触发过牌"
                      >
                        <el-option
                          v-for="(item, index) in roundItem"
                          :label="item.Name"
                          :value="item.Value"
                          :key="'round_' + item.Value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="部门" label-width="80px">
                <el-checkbox-group v-model="fromData.ListDeptGuid">
                  <el-checkbox
                    v-for="(item, index) in deptList"
                    :key="item.DeptGuid"
                    :label="item.DeptGuid"
                    name="type"
                  >
                    {{ item.DeptName }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="分类" label-width="80px">
                <el-checkbox-group v-model="fromData.ListBasicGuid">
                  <el-checkbox
                    v-for="(item, index) in itemBasicList"
                    :key="item.BasicGuid"
                    :label="item.BasicGuid"
                    name="type"
                  >
                    {{ item.BasicName }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <!-- 限制职位与参与 -->
              <el-form-item label="不参与服务" label-width="100px">
                <el-checkbox-group v-model="fromData.IgnoreDuty">
                  <el-checkbox
                    v-for="(item, index) in dutyList"
                    :key="'duty_' + item.DutyNo"
                    :label="item.DutyNo"
                    name="type"
                  >
                    {{ item.DutyName }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="其他">
                <el-checkbox
                  v-model="fromData.PriceChange"
                  :true-label="1"
                  :false-label="0"
                  label="价格灵活"
                  name="type"
                ></el-checkbox>
                <el-checkbox
                  v-model="fromData.CountChange"
                  :true-label="1"
                  :false-label="0"
                  label="数量灵活"
                  name="type"
                ></el-checkbox>
                <el-checkbox
                  v-model="fromData.ModifyRoya"
                  :true-label="1"
                  :false-label="0"
                  label="员工业绩,提成灵活"
                  name="type"
                ></el-checkbox>
                <el-checkbox
                  v-model="fromData.ItemSell"
                  :true-label="1"
                  :false-label="0"
                  label="产品项目"
                  name="type"
                ></el-checkbox>
                <el-checkbox
                  v-model="fromData.WareMatChange"
                  :true-label="1"
                  :false-label="0"
                  label="物料灵活"
                  name="type"
                ></el-checkbox>
              </el-form-item>
              <el-form-item label="介绍详情">
                <el-button type="primary" size="mini" @click="onOpenNoteDrawer">编辑</el-button>
              </el-form-item>
              <el-form-item label="备注">
                <el-input v-model="fromData.ItemNote" type="textarea" :rows="3" clearable></el-input>
              </el-form-item>
            </div>
            <div class="tabs-chile" v-show="isAction == 1">
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="预约项目所属职位" label-width="150px">
                      <el-select
                        v-model="fromData.DutyGuid"
                        @change="onChangeDuty"
                        placeholder="请选择预约项目所属职位"
                      >
                        <el-option
                          v-for="(item, index) in dutyList"
                          :key="item.DutyNo"
                          :label="item.DutyName"
                          :value="item.DutyNo"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="级别" label-width="80px">
                      <el-select
                        v-model="fromData.LevelGuid"
                        placeholder="请选择级别"
                        clearable
                      >
                        <el-option
                          v-for="(item, index) in levelScreenData"
                          :key="item.LevelGuid"
                          :label="item.LevelName"
                          :value="item.LevelGuid"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="预约项目分类显示" label-width="150px">
                      <el-select
                        v-model="fromData.WechatGuid"
                        placeholder="请选择预约项目分类显示"
                      >
                        <el-option
                          v-for="(item, index) in itemWechatList"
                          :key="item.WechatGuid"
                          :label="item.WechatName"
                          :value="item.WechatGuid"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="服务时长" label-width="80px">
                      <el-input
                        v-model="fromData.BookingTime"
                        placeholder="请输入服务时长"
                      >
                        <div slot="append">分钟</div>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="dialog-bottom">
          <el-form-item>
            <el-button type="info" @click="onCloseDialog('formRules')"
              >取消</el-button
            >
            <el-button
              type="primary"
              :disabled="submitDisabled"
              @click="submitForm('formRules')"
              >确认</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>

    <DfQuilEditorDrawer ref="DfQuilEditorDrawer" @onBlurText="onBlurEdit"></DfQuilEditorDrawer>
  </div>
</template>

<script>
import service from "@/api/service.js";
import { baseURL } from "@/utils/axios.js";
export default {
  props: {
    etitle: {
      type: String,
      default: "",
    },

    dialogTableVisible: {
      type: Boolean,
      default: false,
    },

    callFatherFunction: {
      type: Function,
    },

    fromData: {
      type: Object,
      default: () => ({}),
    },

    roundItem: {
      type: Array,
      default: () => [],
    },

    deptList: {
      type: Array,
      default: () => [],
    },

    dutyList: {
      type: Array,
      default: () => [],
    },

    itemBasicList: {
      type: Array,
      default: () => [],
    },

    itemClassList: {
      type: Array,
      default: () => [],
    },

    unitsList: {
      type: Array,
      default: () => [],
    },

    itemWechatList: {
      type: Array,
      default: () => [],
    },

    levelList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imageUrl: "",
      imageList: [],
      dialog: false,
      dialogImageUrl: "",
      dialogVisible: false,
      submitDisabled: false,
      formRules: {
        ItemName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        ClassGuid: [
          { required: true, message: "请选择项目分类", trigger: "change" },
        ],
        UnitsGuid: [
          { required: true, message: "请选择项目单位", trigger: "change" },
        ],
      },
      tabsData: ["基础设置", "预约设置", "回访设置"],
      isAction: 0,
      levelScreenData: [],
    };
  },
  // 1. 添加 2. 修改
  watch: {
    fromData: {
      deep: true,
      handler(newVal) {
        let fileList = this.$refs.DFUpload ? this.$refs.DFUpload.fileList : [];
        if (
          fileList.length != 0 &&
          fileList[0].url == this.fromData.PictureUrl
        ) {
          return;
        }

        let pictureArray =
          this.fromData.PictureUrl != ""
            ? [{ url: this.fromData.PictureUrl }]
            : [];
        this.$nextTick(() => {
          pictureArray != ""
            ? this.$refs.DFUpload.initImageList(pictureArray)
            : null;
        });
      },
    },
  },

  methods: {
    async onUploadSuccess(event) {
      let message = "添加成功";
      let imageUrl = (await event) ? event.data : "";
      let submitData = await { ...this.fromData };
      submitData.Picture = (await imageUrl) ? imageUrl : submitData.Picture;
      submitData.Price =
        this.fromData.Price == "" ? 0 : parseFloat(this.fromData.Price);
      submitData.BookingTime =
        this.fromData.BookingTime == "" ? 0 : this.fromData.BookingTime;

      // 如果有 ItemGuid 就是修改
      if (this.fromData.ItemGuid) {
        submitData.ItemGuid = this.fromData.ItemGuid;
        message = "修改成功";
      }

      try {
        let { data, errcode, errmsg } = await service.saveItem(submitData);

        if (errcode == (await 0)) {
          await this.onCloseDialog("formRules");
          await this.$message.success(message);
        } else {
          await this.$message.error(errmsg);
        }
      } finally {
        this.submitDisabled = false;
      }
    },

    onUploadError(event) {
      // if (!this.fromData.ItemGuid) {
      //   this.onCloseDialog("formRules");
      // }
    },

    async onRemove(file) {
      let submitData = await { ...this.fromData };
      await this.$confirm(`是否删除该图片`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let { data, errcode, errmsg } = await service.deleteItemPricture({
          item_guid: submitData.ItemGuid,
        });
        // // console.log(errcode);
        if (errcode == 0) {
          await this.$message.success("图片删除成功");
          await this.$refs.DFUpload.onRemove(file);
          await this.onCloseDialog("formRules");
        }
      });
    },

    handleRemove(file) {
      // console.log(file);
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleDownload(file) {
      // console.log(file);
    },

    onTabs(index) {
      this.isAction = index;
    },

    onChangeDuty(event) {
      let tempArray = [];
      this.levelList.forEach((item, index) => {
        if (item.DutyNo == event) {
          tempArray.push(item);
        }
      });
      tempArray.unshift({LevelGuid: 'ALL', LevelName: '不限'})
      // this.fromData.DutyGuid = this.selectDuty
      this.levelScreenData = tempArray;
    },

    onCloseDialog(formName) {
      this.isAction = 0;
      this.$refs.DFUpload.fileList = [];
      this.$refs.formRules.resetFields();
      this.$emit("closeDialog");
      this.callFatherFunction();
    },

    submitForm(formName) {
      // console.log(this.fromData);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            let token = await window.sessionStorage.getItem("token");
            let submitData = await { ...this.fromData };

            this.submitDisabled = await true;

            let actionUrl = (await submitData.Picture)
              ? ""
              : baseURL + `/item/upload_item_picture?acctoken=${token}`;
            await this.$refs.DFUpload.submitUpload(actionUrl);
          } catch {
            this.submitDisabled = await false;
          }
        } else {
          return false;
        }
      });
    },

    onOpenNoteDrawer(){
      this.$refs.DfQuilEditorDrawer.onShowDrawer(this.fromData.RichDetail)
    },

    onBlurEdit(event){
      this.fromData.RichDetail = event
    }
  },
};
</script>

<style lang="less" scoped>
.auto {
  height: 73vh;
  overflow-y: scroll;
}
.auto::-webkit-scrollbar-track {
  background-color: #ffffff;
}
.auto::-webkit-scrollbar {
  width: 3px;
}
.auto::-webkit-scrollbar-thumb {
  background-color: #999999;
}
.dialog-top {
  display: flex;
  align-items: center;
  min-height: 200px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  padding: 20px 0;
  .left {
    max-width: 470px;
    margin: 0 20px;
  }
  .right {
    .el-form-item:last-child {
      margin-bottom: 0;
    }
  }
}

.dialog-center {
  //height: 500px;
  margin-top: 20px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  .tabs {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    border-bottom: 1px solid #dcdfe6;
    box-sizing: border-box;
    margin-bottom: 20px;
    > div {
      display: flex;
      align-items: center;
      height: 40px;
      margin-right: 40px;
      box-sizing: border-box;
      border-bottom: 2px solid transparent;
      transition: all 0.5s;
    }
    .action {
      border-bottom: 2px solid #409eff;
    }
  }
  .tabs-chile {
    padding: 0 20px;
  }
}

.dialog-bottom {
  margin-top: 20px;
  .el-form-item {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }
}
</style>
